import jsPDF from 'jspdf';
import headerImg from '../../assets/img/headerImg.png';
import fruitLogo from '../../assets/img/fruitLogo.png';
import allFoodsLogo from '../../assets/img/shortcut_icon/allFoods.png';

import {
  calculateAge,
  calculateAgeOnlyYear,
  formatDate,
  formatAnyDate,
  formatTime,
  formatGender,
  formatStatus,
  formatDietName,
} from '../Functions/formatPersons';
import { anthropometryBold, anthropometryBold1, anthropometryBold2, dietBold, dietBold1, dietBold2 } from '../Functions/formatConsultation';


const getBase64ImageFromURL = async (url) => {
  try {
    const response = await fetch(url, {
      mode: 'cors',
    });
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Erro ao carregar a imagem via fetch:', error);
    throw error;
  }
};

const currentYear = new Date().getFullYear();

const showConsultationPdf = async (
  consultationData,
  patientData,
  dietData,
  anthropometryData,
  preview = false // Se `true`, o PDF será exibido no navegador

) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  // console.log(`Antropometria recebida: ${JSON.stringify(anthropometryData)}`);
  // console.log(JSON.stringify(dietData));

  let currentPage = 1;
  let headerImgData = null;
  let fruitImgLogo = null;
  let allFoodsLogoData = null;


  // Pré-carregar a imagens (cabeçalho / ico / logo)
  try {
    headerImgData = await getBase64ImageFromURL(headerImg);
    fruitImgLogo = await getBase64ImageFromURL(fruitLogo);
    allFoodsLogoData = await getBase64ImageFromURL(allFoodsLogo);
  } catch (error) {
    console.error('Erro ao carregar imagem do cabeçalho:', error);
  }

  function fruitLogoData(pdf, xPosition, yPosition) {
    pdf.addImage(allFoodsLogoData, 'PNG', xPosition, yPosition, 4, 4);
  }




  const addHeader = () => {
    if (headerImgData) {
      pdf.addImage(headerImgData, 'PNG', 0, 0, 210, 20);
    }
    pdf.setFontSize(8);
    pdf.setTextColor(5, 0, 0);
  };


  const addFooter = () => {
    pdf.setTextColor(5, 0, 0);
    const rodapeYPosition = pdf.internal.pageSize.height - 5;
    // pdf.setFontSize(6);
    // pdf.text('powered by', 75, rodapeYPosition - 3, 'center');
    // pdf.setFontSize(7);
    // pdf.text('apit solutions desenvolvimento de software ltda', 105, rodapeYPosition, 'center');

    pdf.setFontSize(6);
    pdf.text(`© ${currentYear}`, 100, rodapeYPosition + 0.5, 'center');
    pdf.setFontSize(7);
    pdf.text('Nutrição App', 118, rodapeYPosition + 0.5, 'center');
    pdf.setLineWidth(0.4); // Espessura da linha
    pdf.line(1, rodapeYPosition - 4, 209, rodapeYPosition - 3.5);
    pdf.addImage(fruitLogo, 'PNG', 105, rodapeYPosition - 2, 5, 3.5);
    pdf.text(`${currentPage}`, 205, rodapeYPosition, 'center');
  };

  const addSectionBox = (pdf, title, yPosition, contentCallback) => {
    // Obtém a altura do conteúdo gerado
    let boxHeight = contentCallback(pdf, yPosition);

    // Adiciona um espaçamento extra abaixo do conteúdo
    const extraSpacing = 5; // Espaçamento extra em milímetros
    boxHeight += extraSpacing;

    // Configurações para desenhar a borda
    pdf.setDrawColor(139, 197, 64); // Cor da borda
    pdf.setLineWidth(0.2);          // Espessura da linha da borda
    pdf.roundedRect(10, yPosition - 5, 190, boxHeight, 3, 3); // Desenha o retângulo arredondado

    // Configurações para o título
    pdf.setTextColor(139, 197, 64); // Cor do título
    pdf.setFontSize(9);             // Tamanho da fonte
    pdf.setFont('Helvetica', 'bold');
    pdf.text(title, 12, yPosition); // Adiciona o título na posição especificada
    pdf.setFont('Helvetica', 'normal');
    // Configurações para o texto padrão
    pdf.setTextColor(3, 3, 3);      // Cor do texto
    pdf.setFontSize(8);             // Tamanho padrão da fonte

    // Retorna a nova posição vertical ajustada
    return yPosition + boxHeight + 10; // Inclui espaçamento entre as seções
  };

  // const addPageContent = async () => {
  //   const maxPageHeight = pdf.internal.pageSize.height - 20;
  //   let yPosition = 30;

  //   await addHeader();

  //   // Ajuste para calcular altura corretamente
  //   yPosition = addSectionBox(pdf, 'Consulta', yPosition, (pdf, yPosition) => {
  //     const initialY = yPosition + 5; // Posição inicial para o conteúdo
  //     const lineSpacing = 5; // Espaçamento entre as linhas
  //     let currentY = initialY;

  //     // Primeira linha (itens na mesma linha)
  //     pdf.text(`Data da Consulta: ${formatDate(consultationData.consult_date)}`, 12, currentY);
  //     pdf.text(`Status: ${formatStatus(consultationData.status) || 'Não informado'}`, 60, currentY);
  //     pdf.text(`Profissional: ${consultationData.professional_name || 'N/A'}`, 95, currentY);
  //     pdf.text(`Profissional: ${consultationData.professional_name || 'N/A'}`, 95, currentY);
  //     // Segunda linha
  //     // currentY += lineSpacing;
  //     // pdf.text(`Profissional: ${consultationData.professional_name || 'N/A'}`, 12, currentY);

  //     // Retorna a altura necessária
  //     return (currentY - initialY + lineSpacing + 5); // Altura total calculada
  //   });

  //   // yPosition = addSectionBox(pdf, 'Consulta', yPosition, (pdf, yPosition) => {
  //   //   pdf.text(`Data da Consulta: ${formatDate(consultationData.consult_date)}`, 12, yPosition + 5);
  //   //   pdf.text(`Status: ${formatStatus(consultationData.status) || 'Não informado'}`, 60, yPosition + 5);
  //   //   pdf.text(`Profissional: ${consultationData.professional_name || 'N/A'}`, 12, yPosition + 10);
  //   // });

  //   yPosition = addSectionBox(pdf, 'Paciente', yPosition, (pdf, yPosition) => {
  //     const initialY = yPosition; // Posição inicial para o conteúdo
  //     const lineSpacing = 5; // Espaçamento entre as linhas
  //     let currentY = initialY;

  //     pdf.text(`Nome: ${patientData.first_name} ${patientData.last_name}`, 12, yPosition + 5);
  //     pdf.text(`Idade: ${calculateAgeOnlyYear(patientData.birth_date)} Gênero: ${formatGender(patientData.gender)} CPF: ${patientData.cpf || 'N/A'}`, 125, yPosition + 5);
  //     pdf.text(`Telefone: ${patientData.mobile || 'N/A'}`, 12, yPosition + 10);
  //     pdf.text(`Telefone: ${patientData.phone || 'N/A'}`, 50, yPosition + 10);
  //     pdf.text(`E-mail: ${patientData.email || 'N/A'}`, 90, yPosition + 10);

  //     currentY += lineSpacing;
  //     pdf.text(`Endereço: ${patientData.address.street || 'N/A'}  Número: ${patientData.address.number}  Complemento: ${patientData.address.complement}`, 12, yPosition + 15);
  //     currentY += lineSpacing;
  //     pdf.text(`Bairro: ${patientData.address.neighborhood || 'N/A'} - Cidade: ${patientData.address.city || 'N/A'}  - Estado: ${patientData.address.uf || 'N/A'}  - CEP: ${patientData.address.zipcode}`, 12, yPosition + 20);
  //     return (currentY - initialY + lineSpacing + 10); // Altura total calculada
  //   });

  //   yPosition = addSectionBox(pdf, 'Consulta Prontuário', yPosition, (pdf, yPosition) => {
  //     const initialY = yPosition + 5; // Posição inicial para o conteúdo
  //     const lineSpacing = 5; // Espaçamento entre as linhas
  //     let currentY = initialY;

  //     if (consultationData) {
  //       pdf.text(`Data: ${formatDate(consultationData.consult_date)}`, 12, currentY);
  //       currentY += lineSpacing;

  //       pdf.text(`Doenças Atuais: ${consultationData.current_illnesses || 'N/A'}`, 12, currentY);
  //       currentY += lineSpacing;

  //       pdf.text(`Doenças Pré existentes: ${consultationData.past_illnesses || 'N/A'}`, 12, currentY);
  //       currentY += lineSpacing;

  //       pdf.text(`Alergias e Intolerâncias: ${consultationData.allergies || 'N/A'}`, 12, currentY);
  //       currentY += lineSpacing;

  //       pdf.text(`Histórico de Cirurgias: ${consultationData.surgeries || 'N/A'}`, 12, currentY);
  //       currentY += lineSpacing;

  //       pdf.text(`Medicamentos: ${consultationData.medications || 'N/A'}`, 12, currentY);
  //       currentY += lineSpacing;

  //       pdf.text(`Histórico Familiar: ${consultationData.family_history || 'N/A'}`, 12, currentY);
  //       currentY += lineSpacing;
  //     } else {
  //       pdf.text('Sem dados disponíveis.', 12, currentY);
  //       currentY += lineSpacing;
  //     }

  //     // Retorna a altura total calculada com base na posição atual
  //     return currentY - initialY + lineSpacing;
  //   });


  //   yPosition = addSectionBox(pdf, 'Antropometria', yPosition, (pdf, yPosition) => {
  //     const initialY = yPosition + 5;
  //     const lineSpacing = 6;
  //     let currentY = initialY;

  //     if (Array.isArray(anthropometryData) && anthropometryData.length > 0) {
  //       const data = anthropometryData[0]; // Usa o primeiro registro de antropometria

  //       pdf.text(`Data da Medição: ${formatDate(data.measurement_date)}`, 12, currentY);
  //       pdf.text(`Altura: ${data.height ? `${data.height} cm` : 'N/A'}`, 55, currentY);
  //       pdf.text(`Peso: ${data.current_weight ? `${data.current_weight} kg` : 'N/A'}`, 80, currentY);
  //       pdf.text(`IMC: ${data.bmi || 'N/A'} kg/m²`, 100, currentY);
  //       pdf.text(`Classificação: ${data.bray_classification || 'N/A'}`, 125, currentY);
  //       currentY += lineSpacing;
  //     } else {
  //       pdf.text('Sem dados de antropometria disponíveis.', 12, currentY);
  //       currentY += lineSpacing;
  //     }
  //     // Retorna a altura total calculada com base na posição atual
  //     return currentY - initialY + lineSpacing;
  //   });



  //   yPosition = addSectionBox(pdf, 'Dietas', yPosition, (pdf, yPosition) => {
  //     const initialY = yPosition + 5;
  //     const lineSpacing = 5;
  //     let currentY = initialY;

  //     if (Array.isArray(dietData) && dietData.length > 0) {
  //       dietData.forEach((diet, index) => {
  //         pdf.text(`Dieta ${index + 1}: ${formatDietName(diet.name) || 'N/A'}`, 12, currentY);
  //         currentY += lineSpacing;
  //         pdf.text(`Objetivo: ${diet.objective || 'N/A'}`, 12, currentY);
  //         pdf.text(`Calorias Totais: ${diet.diet_total_calories || 'N/A'} kcal`, 100, currentY);

  //         if (Array.isArray(diet.meals) && diet.meals.length > 0) {
  //           diet.meals.forEach((meal, mealIndex) => {
  //             currentY += lineSpacing;
  //             pdf.text(`Refeição ${mealIndex + 1}: ${meal.name || 'N/A'}`, 12, currentY);
  //             pdf.text(`Hora: ${formatTime(meal.time) || 'N/A'}`, 55, currentY);

  //             if (Array.isArray(meal.foods) && meal.foods.length > 0) {
  //               meal.foods.forEach((food, foodIndex) => {
  //                 currentY += lineSpacing;
  //                 pdf.text(
  //                   `• Alimento ${foodIndex + 1}: ${food.food_name || 'N/A'} (${food.quantity} ${food.unit_measurement})`,
  //                   22,
  //                   currentY
  //                 );
  //               });
  //             }
  //           });
  //         }
  //         currentY += lineSpacing;
  //       });

  //       return currentY - initialY + lineSpacing;
  //     } else {
  //       pdf.text('Sem dietas cadastradas.', 12, currentY);
  //       return 10;
  //     }
  //   });

  //   // yPosition = addSectionBox(pdf, 'Dietas', yPosition, (pdf, yPosition) => {
  //   //   const initialY = yPosition + 5;
  //   //   const lineSpacing = 5;
  //   //   const columnSpacing = 100; // Espaço entre as colunas
  //   //   const column1X = 12; // X inicial da coluna 1
  //   //   const column2X = column1X + columnSpacing; // X inicial da coluna 2
  //   //   const maxY = pdf.internal.pageSize.height - 20; // Altura máxima da página
  //   //   let currentY = initialY;
  //   //   let isLeftColumn = true; // Controle para alternar entre colunas

  //   //   if (Array.isArray(dietData) && dietData.length > 0) {
  //   //     dietData.forEach((diet, index) => {
  //   //       const currentX = isLeftColumn ? column1X : column2X;

  //   //       // Renderizar título da dieta
  //   //       pdf.text(`Dieta ${index + 1}: ${formatDietName(diet.name) || 'N/A'}`, currentX, currentY);
  //   //       currentY += lineSpacing;
  //   //       pdf.text(`Objetivo: ${diet.objective || 'N/A'}`, currentX, currentY);
  //   //       pdf.text(`Calorias Totais: ${diet.diet_total_calories || 'N/A'} kcal`, currentX, currentY + lineSpacing);

  //   //       if (Array.isArray(diet.meals) && diet.meals.length > 0) {
  //   //         diet.meals.forEach((meal, mealIndex) => {
  //   //           currentY += lineSpacing * 2; // Espaço entre refeições
  //   //           pdf.text(`Refeição: ${meal.name || 'N/A'}`, currentX, currentY);
  //   //           pdf.text(`Hora: ${formatTime(meal.time) || 'N/A'}`, currentX + 40, currentY);

  //   //           if (Array.isArray(meal.foods) && meal.foods.length > 0) {
  //   //             meal.foods.forEach((food, foodIndex) => {
  //   //               currentY += lineSpacing;
  //   //               pdf.text(
  //   //                 `• ${food.food_name || 'N/A'} (${food.quantity || 'N/A'} ${food.unit_measurement || ''})`,
  //   //                 currentX + 10,
  //   //                 currentY
  //   //               );
  //   //             });
  //   //           }
  //   //         });
  //   //       }

  //   //       // Alternar entre colunas e checar se precisa de uma nova página
  //   //       isLeftColumn = !isLeftColumn;
  //   //       if (!isLeftColumn) {
  //   //         currentY += lineSpacing * 4; // Espaço extra após a segunda coluna
  //   //       }

  //   //       if (currentY > maxY) {
  //   //         pdf.addPage();
  //   //         currentY = initialY;
  //   //         isLeftColumn = true;
  //   //       }
  //   //     });

  //   //     return currentY - initialY + lineSpacing;
  //   //   } else {
  //   //     pdf.text('Sem dietas cadastradas.', column1X, currentY);
  //   //     return 10;
  //   //   }
  //   // });




  //   if (yPosition + 20 > maxPageHeight) {
  //     pdf.addPage();
  //     currentPage++;
  //     yPosition = 30;
  //     await addHeader();
  //     addFooter();
  //   }

  //   addFooter();
  // };

  const addPageContent = async () => {
    const maxPageHeight = pdf.internal.pageSize.height - 20; // Altura máxima da página
    let yPosition = 30; // Posição inicial do conteúdo

    await addHeader();

    // Lógica para adicionar conteúdo e verificar altura
    const addSectionSafely = (title, callback) => {
      if (yPosition + 20 > maxPageHeight) {
        addFooter(currentPage); // Adicione o rodapé antes de mudar a página
        pdf.addPage(); // Adiciona uma nova página
        currentPage++; // Incrementa o contador de páginas
        yPosition = 30; // Reseta a posição vertical
        addHeader(); // Adiciona o cabeçalho na nova página
      }
      yPosition = addSectionBox(pdf, title, yPosition, callback);
    };

    // Adicione suas seções de conteúdo
    addSectionSafely('Consulta', (pdf, yPosition) => {
      const initialY = yPosition + 5;
      const lineSpacing = 5;
      let currentY = initialY;

      pdf.text(`Data da Consulta: ${formatDate(consultationData.consult_date)}`, 12, currentY);
      pdf.text(`Status: ${formatStatus(consultationData.status) || 'Não informado'}`, 60, currentY);
      pdf.text(`Profissional: ${consultationData.professional_name || 'N/A'}`, 95, currentY);
      return currentY - initialY + lineSpacing + 5;
    });

    addSectionSafely('Paciente', (pdf, yPosition) => {
      const initialY = yPosition + 5;
      const lineSpacing = 5;
      let currentY = initialY;

      pdf.text(`Nome: ${patientData.first_name} ${patientData.last_name}`, 12, currentY);
      currentY += lineSpacing;
      pdf.text(`Idade: ${calculateAgeOnlyYear(patientData.birth_date)}      Gênero: ${formatGender(patientData.gender)}         CPF: ${patientData.cpf || 'N/A'}`, 12, currentY);
      currentY += lineSpacing;
      pdf.text(`Telefone: ${patientData.mobile || 'N/A'}`, 12, currentY);
      pdf.text(`E-mail: ${patientData.email || 'N/A'}`, 60, currentY);

      return currentY - initialY + lineSpacing + 5;
    });

    addSectionSafely('Consultas Prontuário', (pdf, yPosition) => {
      const initialY = yPosition + 5; // Posição inicial para o conteúdo
      const lineSpacing = 5; // Espaçamento entre as linhas
      let currentY = initialY;

      if (consultationData) {
        pdf.text(`Data: ${formatDate(consultationData.consult_date)}`, 12, currentY);
        currentY += lineSpacing;

        pdf.text(`Doenças Atuais: ${consultationData.current_illnesses || 'N/A'}`, 12, currentY);
        currentY += lineSpacing;

        pdf.text(`Doenças Pré existentes: ${consultationData.past_illnesses || 'N/A'}`, 12, currentY);
        currentY += lineSpacing;

        pdf.text(`Alergias e Intolerâncias: ${consultationData.allergies || 'N/A'}`, 12, currentY);
        currentY += lineSpacing;

        pdf.text(`Histórico de Cirurgias: ${consultationData.surgeries || 'N/A'}`, 12, currentY);
        currentY += lineSpacing;

        pdf.text(`Medicamentos: ${consultationData.medications || 'N/A'}`, 12, currentY);
        currentY += lineSpacing;

        pdf.text(`Histórico Familiar: ${consultationData.family_history || 'N/A'}`, 12, currentY);
        currentY += lineSpacing;
      } else {
        pdf.text('Sem dados disponíveis.', 12, currentY);
        currentY += lineSpacing;
      }

      // Retorna a altura total calculada com base na posição atual
      return currentY - initialY + lineSpacing;
    });

    addSectionSafely('Dietas', (pdf, yPosition) => {

      const initialY = yPosition + 5;
      const lineSpacing = 5;
      let currentY = initialY;
      pdf.addImage(allFoodsLogoData, 'PNG', (currentY + 30), (initialY - 5), 16, 16);
      if (Array.isArray(dietData) && dietData.length > 0) {
        dietData.forEach((diet, index) => {
          pdf.setFont("helvetica", "bold");
          pdf.text(`Dieta ${index + 1}: `, 12, currentY);
          pdf.setFont("helvetica", "normal");
          pdf.text(`${formatDietName(diet.name) || 'N/A'}`, 25, currentY);
          pdf.setFont("helvetica", "bold");
          pdf.text(`Objetivo: `, 92, currentY);
          pdf.setFont("helvetica", "normal");
          pdf.text(`${diet.objective || 'N/A'}`, 105, currentY);
          currentY += lineSpacing;
          pdf.setFont("helvetica", "bold");
          pdf.text(`Calorias Totais desta Dieta: `, 12, currentY);
          pdf.setFont("helvetica", "normal");
          pdf.text(`${diet.diet_total_calories || 'N/A'} kcal/dia`, 50, currentY);
          currentY += lineSpacing;
          if (Array.isArray(diet.meals) && diet.meals.length > 0) {
            diet.meals.forEach((meal, mealIndex) => {
              currentY += lineSpacing;

              dietBold1(pdf, mealIndex, meal, currentY, formatTime);

              if (Array.isArray(meal.foods) && meal.foods.length > 0) {
                meal.foods.forEach((food, foodIndex) => {
                  currentY += lineSpacing;

                  fruitLogoData(pdf, (initialY - 135), (currentY - 3.5))
                  pdf.text(`• Alimento ${foodIndex + 1}:`, 22, currentY);
                  pdf.text(`Qtd: ${food.quantity} ${food.unit_measurement}  -  ${food.food_name || 'N/A'}`, 40, currentY);
                  pdf.text(`${food.calories || '0'} kcal`, 168, currentY);
                });
              }
            });
          }
          currentY += lineSpacing;
        });
        return currentY - initialY + lineSpacing;
      } else {
        pdf.text('Sem dietas cadastradas.', 12, currentY);
        return 10;
      }

    });

    addSectionSafely('Antropometria', (pdf, yPosition) => {
      const initialY = yPosition + 5;
      const lineSpacing = 6;
      let currentY = initialY;

      if (Array.isArray(anthropometryData) && anthropometryData.length > 0) {
        const data = anthropometryData[0];
        anthropometryBold1(pdf, data, currentY, lineSpacing, formatAnyDate);
        currentY += lineSpacing * 6;

      } else {
        pdf.text('Sem dados de antropometria disponíveis.', 12, currentY);
        currentY += lineSpacing;
      }

      return currentY - initialY + lineSpacing + 5;
    });

    // Adicione a última página se necessário
    if (yPosition + 20 > maxPageHeight) {
      addFooter(currentPage);
      pdf.addPage();
      currentPage++;
      addHeader();
    }

    addFooter(currentPage);
  };



  await addPageContent();

  const pdfBlob = pdf.output('blob'); // Gera o Blob do PDF

  if (preview) {
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank'); // Abre o PDF no navegador
  }

  return pdfBlob; // Retorna o Blob para outros usos (e.g., enviar por e-mail)

};

export default showConsultationPdf;
