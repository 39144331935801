import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import '../styles/ConfirmedAppointment.css';

function ConfirmedAppointmentEmail() {
  const location = useLocation();
  const [appointmentData, setAppointmentData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Recupera as variáveis da URL
    const params = new URLSearchParams(location.search);
    const appointmentData = {
      patient_first_name: params.get("patient_first_name"),
      patient_last_name: params.get("patient_last_name"),
      professional_name: params.get("professional_name"), // Corrigido para "professional_name"
      appointment_date: params.get("appointment_date"),
      appointment_time: params.get("appointment_time"),
      appointment_id: params.get("appointment_id"),
    };
    setAppointmentData(appointmentData);
  }, [location]);

  if (!appointmentData) {
    return <div>Carregando...</div>;
  }

  const handleRedirect = () => {
    window.location.href = "https://www.estima.nutricaoapp.com.br";
  };

  // Variáveis extraídas do estado
  const {
    patient_first_name,
    patient_last_name,
    professional_name,
    appointment_date,
    appointment_time,
    appointment_id
  } = appointmentData;

  return (
    <>
      <div className="confirmed_email">
      </div>

      <div className="email-body">
        <div className="email-header"></div>
        <div align="center" className="confirmation-button-container">
          <p className="confirmation-button">
            Agendamento Confirmado !
          </p>
        </div>
        <div className="email-footer">
          &copy; {new Date().getFullYear()} Nutrição App - Todos os direitos reservados
        </div>
        <div>
          <button type="button" className="exitBtn" onClick={handleRedirect}>sair</button>
        </div>

      </div >

    </>
  );
}

export default ConfirmedAppointmentEmail;
