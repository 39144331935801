import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/auth';
import { fetchAppointmentById, updateAppointmentById } from '../../../redux/actions/appointmentsActions';
import { fetchConsultationByAppointmentId } from '../../../redux/actions/consultationActions';
import { emailAppointmentPdf, sendEmail } from '../../../service/api';
import { MdEmail, MdPrint, MdExitToApp, MdEdit } from 'react-icons/md';
import { FaUserMd } from "react-icons/fa";
import showAppointmentPdf from '../../../utils/PDF/showAppointmentPdf';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';

import '../styles/ShowAppointment.css';
import { BiMailSend } from "react-icons/bi";

function ShowAppointment() {
  const { loginUser } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { appointment_id } = useParams();

  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [ellipsis, setEllipsis] = useState('.....');

  const icoDoctor2 = <FaUserMd className='icon' />
  const startConsult = "Iniciar Consulta";

  const { currentAppointment, loading, error } = useSelector((state) => state.appointments);
  const [appointmentData, setAppointmentData] = useState(null);

  const [consultationData, setConsultationData] = useState(null);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    let interval;
    if (isSending) {
      let count = 5;
      interval = setInterval(() => {
        setEllipsis('.'.repeat(count));
        count = count === 1 ? 5 : count - 1;
      }, 500);
    } else {
      setEllipsis(''); // Certifica-se de que o ellipsis para ao concluir o envio
    }

    return () => clearInterval(interval);
  }, [isSending]);

  useEffect(() => {
    if (isSending) {
      setMessage(<><BiMailSend className="ico" /> {ellipsis} Enviando</>);
    }
  }, [ellipsis, isSending]);

  useEffect(() => {
    if (appointment_id) {
      dispatch(fetchConsultationByAppointmentId(appointment_id))
        .then((response) => setConsultationData(response.payload))
        .catch((error) => console.error('Erro ao buscar consulta:', error));
    }
  }, [dispatch, appointment_id]);

  // window.history.replaceState(null, '', '/nutricaoapp'); Disfarça a rota real para esta página

  useEffect(() => {
    if (appointment_id) {
      dispatch(fetchAppointmentById(appointment_id))
        .then((response) => {
          setAppointmentData(response.payload);
        })
        .catch((error) => {
          console.error('Error fetching appointment:', error);
        });
    }
  }, [dispatch, appointment_id]);

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'Hora inválida';
    const timeParts = timeString.split(':');
    if (timeParts.length < 2) return 'Hora inválida';
    const [hour, minute] = timeParts;
    return `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`;
  };

  const handlePrint = () => {
    // console.log("Consultation Data: " + JSON.stringify(consultationData));
    // console.log("Appointment Data: " + JSON.stringify(appointmentData));
    if (!appointmentData) {
      console.error("Erro: Dados do agendamento não estão disponíveis.");
      return;
    }

    if (!consultationData) {
      console.warn("Aviso: Dados da consulta não estão disponíveis.");
    }

    // Passa os dados para a função de geração do PDF
    showAppointmentPdf(appointmentData, consultationData, true);

  };

  const handleConfirmAction = () => {
    setMessage(`Deseja enviar detalhes deste agendamento para ${appointmentData.patient_email}?`);
    setShowConfirmationModal(true);
  };

  const handleEmail = async () => {
    try {
      setIsSending(true);
      setShowConfirmationModal(false);
      setShowModal(true);

      // Reseta ellipsis antes de iniciar a animação
      setEllipsis('.....');

      // Gera o PDF (Blob)
      const pdfBlob = await showAppointmentPdf(appointmentData, consultationData);

      // Converte o Blob para um arquivo válido
      const attachment = new File([pdfBlob], 'Agendamento.pdf', { type: 'application/pdf' });

      // Monta o FormData
      const formData = new FormData();
      formData.append('appointment_id', Number.parseInt(appointmentData.appointment_id));
      formData.append('to', appointmentData.patient_email);
      formData.append('subject', 'Detalhes do Agendamento');
      formData.append('patient_first_name', appointmentData.patient_name);
      formData.append('patient_last_name', appointmentData.patient_name);
      formData.append('professional_name', appointmentData.professional_name);
      formData.append('appointment_date', formatDate(appointmentData.appointment_date));
      formData.append('appointment_time', formatTime(appointmentData.appointment_time));
      formData.append('company_name', appointmentData.company_name);
      formData.append('currentYear', currentYear);
      formData.append('attachment', attachment);

      // console.log('FormData antes do envio:', Array.from(formData.entries()));

      // **Implementando Timeout de 25 segundos**
      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("timeout")), 25000) // 25 segundos
      );

      // Simulação do envio (substitua pelo request real)
      // const emailSimulation = new Promise((resolve) =>
      //   setTimeout(() => resolve("Email enviado com sucesso!"), 5000) // Simulação de 5s
      // );
      // const response = await Promise.race([emailSimulation, timeoutPromise]);

      // Aguarda a resposta do e-mail ou timeout
      const response = await Promise.race([emailAppointmentPdf(formData), timeoutPromise]);

      console.log('Resposta do envio:', response);

      // **Para a animação e impede a atualização da mensagem**
      setIsSending(false);

      // Atualiza o modal com a mensagem de sucesso
      setMessage(
        <>
          <span className="spanDataChecked">✓</span> E-mail enviado com sucesso!
        </>
      );

      // Exibe mensagem detalhada após 3 segundos
      setTimeout(() => {
        setMessage(
          <div className='message-line'>
            E-mail enviado para <BiMailSend className="ico" /> {appointmentData.patient_email}
          </div>
        );
      }, 4000);

      // Oculta o modal após 5 segundos
      setTimeout(() => {
        setShowModal(false);
      }, 6000);
    } catch (error) {
      console.error('Erro ao enviar e-mail:', error);

      // **Para a animação e impede a atualização da mensagem**
      setIsSending(false);

      // Se o erro for um timeout, exibe a mensagem específica
      if (error.message === "timeout") {
        setMessage(
          <>
            <span className="spanDataTop">Humm, parece que isso está demorando muito :( Tente novamente mais tarde.</span>
          </>
        );
      } else {
        // Exibe mensagem de erro genérica
        setMessage(
          <>
            <span className='spanDataTop'>Falha ao enviar o e-mail para</span> <span className="spanDataTop">{appointmentData.patient_email}</span>.
          </>
        );
      }

      // Mantém o modal aberto para exibir erro
      setShowModal(true);

      // Oculta o modal após 4 segundos
      setTimeout(() => {
        setShowModal(false);
      }, 4000);
    }
  };


  const handleCancelAction = () => {
    setShowConfirmationModal(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleUpdate = (appointment_id) => {
    dispatch(fetchAppointmentById(appointment_id));
    navigate(`/home/appointment/update/${appointment_id} `);
  };

  const handleClickBack = (e) => {
    e.preventDefault();
    navigate("/home/appointment/get");
  };

  // const handleStartConsult = (appointment_id) => {
  //   if (!appointmentData?.appointment_id) {
  //     console.error("O appointment_id está indefinido.");
  //     return;
  //   }
  //   dispatch(clearAppointment()); // Limpa o agendamento anterior
  //   dispatch(fetchAppointmentById(appointment_id)); // Carrega o novo agendamento
  //   navigate(`/home/consultation/create/${ appointment_id } `); // Navega para a nova consulta
  // };

  const handleStartConsult = async (appointment_id) => {
    if (!appointment_id) {
      console.error("Erro: ID do agendamento está indefinido.");
      return;
    }

    try {
      // console.log("ID do agendamento ao iniciar consulta:", appointment_id);

      // Atualize o status do agendamento (se necessário)
      // await api.patch(`/ appointments / ${ appointment_id } `, { status: 'in_progress' });
      await updateAppointmentById(`/appointments/${appointment_id}`, { status: 'in_progress' });

      // Redirecione para a criação da consulta com o estado necessário
      navigate(`/home/consultation/create/${appointment_id} `, {
        state: { appointment_id }, // Inclua aqui os dados necessários
      });
    } catch (error) {
      console.error('Erro ao iniciar consulta:', error);
    }
  };



  const hasRole = (role) => loginUser.roles?.includes(role);
  const allRoles = ['admin', 'manager', 'coordinator', 'user'];
  const hasAllRoles = () => allRoles.every((role) => hasRole(role));

  const formatStatus = (status) => {
    switch (status) {
      case 'confirmed':
        return 'Confirmado';
      case 'scheduled':
        return 'Agendado';
      case 'changed':
        return 'Alterado';
      case 'in_progress':
        return 'Em Andamento';
      case 'completed':
        return 'Concluído';
      case 'cancelled':
        return 'Cancelado';
      default:
        return '';
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro ao carregar os dados: {error}</div>;
  }

  if (!appointmentData) {
    return <div>Nenhum compromisso encontrado.</div>;
  }

  return (
    <>
      <h3>Detalhes do Compromisso</h3>
      <form className="boxShowAppointment">
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="company_name">Empresa:</label>
            <span className='spanData'>{appointmentData.company_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="professional_name">Profissional:</label>
            <span className='spanData'>{appointmentData.professional_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="patient_name">Paciente:</label>
            <span className='spanData'>{appointmentData.patient_name}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="patient_name">Email / Telefone:</label>
            <span className='spanData'>{appointmentData.patient_email} / {appointmentData.patient_phone}</span>
          </div>
        </div>

        <h5>Data do Compromisso</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="appointment_date">Data do Compromisso:</label>
            <span className='spanData'>{formatDate(appointmentData.appointment_date)}</span>
          </div>
        </div>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="appointment_time">Hora do Compromisso:</label>
            <span className='spanData'>{formatTime(appointmentData.appointment_time)}</span>
          </div>
        </div>
        <div className='box-line'>
          <div className="boxCol1">
            <label htmlFor="status">Status:</label>
            <span className='spanData'>{formatStatus(appointmentData.status)}</span>
          </div>
        </div>

        <h5>Observações</h5>
        <div className="box-line">
          <div className="boxCol1">
            <label htmlFor="notes">Notas:</label>
            <span className='spanData'>{appointmentData.notes}</span>
          </div>
        </div>

        <div className="boxButtons1">
          <button className='showBoxBtn' type="button" onClick={handleConfirmAction}>
            <MdEmail className='sideIcoBtn' />
            <span className='sideBtnSpan'>Email</span>
          </button>
        </div>
        <div className="boxButtons2">
          <button className='showBoxBtn' type="button" onClick={() => handlePrint(appointmentData, consultationData)}>
            <MdPrint className='sideIcoBtn' />
            <span className='sideBtnSpan'>Imprimir</span>
          </button>
        </div>
        <div className="boxButtons3">
          {(!hasRole(allRoles)) && (
            <button className='showBoxBtn' type="button" onClick={() => handleUpdate(appointment_id)}>
              <MdEdit className='sideIcoBtn' />
              <span className='sideBtnSpan'>Editar</span>
            </button>
          )}
        </div>
        <div className="boxButtons4">
          <button className='showBoxBtn' type="button" onClick={handleClickBack}>
            <MdExitToApp className='sideIcoBtn' />
            <span className='sideBtnSpan'>Voltar</span>
          </button>
        </div>
        {/* <div>
          {hasRole('Admin') ? (

            <div className="boxButtons5">
              {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
                <button className='showBoxBtn' type="button" onClick={() => handleStartConsult(appointmentData.appointment_id)}>
                  <FaUserMd className='sideIcoBtn' />
                  <span className='sideBtnSpan'>Nova Consulta</span>
                </button>
              )}
            </div>
          ) : null}
        </div> */}

        <div className="boxButtons5">
          {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
            <>
              {consultationData ? (
                // Se já existe uma consulta
                <button
                  // className="showBoxBtn"
                  className={
                    appointmentData.status === 'completed' || 'in_progress' ? 'showActionBtn-disable' : 'showBoxbtn'
                  }
                  type="button"
                  onClick={() => navigate(`/home/consultation/show/${consultationData.consultation_id} `)}
                >
                  <FaUserMd className="sideIcoBtn" />
                  <span className="sideBtnSpan">Acessar Consulta</span>
                </button>
              ) : (
                // Se não existe uma consulta
                <button
                  // className="showBoxBtn"
                  className={
                    appointmentData.status === 'completed' || 'in_progress' ? 'showActionBtn-disable' : 'showBoxbtn'
                  }
                  type="button"
                  onClick={() => handleStartConsult(appointmentData.appointment_id)}
                >
                  <FaUserMd className="sideIcoBtn" />
                  <span className="sideBtnSpan">Iniciar Consulta</span>
                </button>
              )}
            </>
          )}
        </div>
        <>
          {showConfirmationModal && (
            <ConfirmationModal
              message={message}
              onConfirm={handleEmail}
              onCancel={handleCancelAction}
            />
          )}

          {showModal && (
            <ModalForms
              message={message}
              isSending={isSending}
              onClose={() => setShowModal(false)}
            />
          )}
        </>

      </form>
      {/* {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
        <div className='btnNewConsult'>
          {appointmentData.status !== 'completed' && appointmentData.status !== 'cancelled' && (
            <button className='icoBtn' onClick={() => handleStartConsult(appointment_id)} data-tip={startConsult}>{icoDoctor2}</button>
          )}
        </div>
      )} */}


    </>
  );
}

export default ShowAppointment;
