import jsPDF from 'jspdf';
import headerImg from '../../assets/img/headerImg.png';
import fruitLogo from '../../assets/img/fruitLogo.png';

import {
  formatDate,
  formatTime,
  formatStatus,
} from '../Functions/formatPersons';

const getBase64ImageFromURL = async (url) => {
  try {
    const response = await fetch(url, {
      mode: 'cors', // Importante para habilitar CORS
    });
    const blob = await response.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result); // Retorna a imagem em Base64
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Lê o blob e converte para Base64
    });
  } catch (error) {
    console.error('Erro ao carregar a imagem via fetch:', error);
    throw error;
  }
};

const currentYear = new Date().getFullYear();

// Pré-carregar a imagens (cabeçalho / ico / logo)
try {
  fruitLogo = await getBase64ImageFromURL(fruitLogo);
} catch (error) {
  console.error('Erro ao carregar imagem do cabeçalho:', error);
}


const showAppointmentPdf = async (
  appointmentData,
  consultationData,
  preview = false
) => {
  const pdf = new jsPDF({
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
  });

  let currentPage = 1;

  // console.log(appointmentData, null, 2);
  // console.log(consultationData, null, 2);

  const addHeader = async () => {
    try {
      const imgData = await getBase64ImageFromURL(headerImg);
      pdf.addImage(imgData, 'PNG', 0, 0, 210, 20); // Cabeçalho da página
    } catch (error) {
      console.error("Erro ao carregar imagem do cabeçalho", error);
    }

    pdf.setFontSize(8);
    pdf.setTextColor(5, 0, 0);
  };

  const addFooter = () => {
    pdf.setTextColor(5, 0, 0);
    const rodapeYPosition = pdf.internal.pageSize.height - 5;
    pdf.setFontSize(6);
    // pdf.text('powered by', 75, rodapeYPosition - 3, 'center');
    pdf.text(`© ${currentYear}`, 100, rodapeYPosition + 0.5, 'center');
    pdf.setFontSize(7);
    // pdf.text('apit solutions desenvolvimento de software ltda', 105, rodapeYPosition, 'center');
    pdf.text('Nutrição App', 118, rodapeYPosition + 0.5, 'center');
    pdf.setLineWidth(0.4); // Espessura da linha
    pdf.line(1, rodapeYPosition - 4, 209, rodapeYPosition - 3.5);
    pdf.addImage(fruitLogo, 'PNG', 105, rodapeYPosition - 2, 5, 3.5);
    pdf.text(`${currentPage}`, 205, rodapeYPosition, 'center');
  };

  const addSectionBox = (pdf, title, yPosition, contentCallback) => {
    const boxHeight = contentCallback(pdf, yPosition); // Retorna a altura do conteúdo
    // Define a cor da borda do box
    pdf.setDrawColor(139, 197, 64);
    pdf.setLineWidth(0.2);
    pdf.roundedRect(10, yPosition - 5, 190, boxHeight, 3, 3);
    // Define a cor do título
    pdf.setTextColor(139, 197, 64); // Cor do título
    pdf.setFontSize(9);             // Tamanho da fonte
    pdf.setFont('Helvetica', 'bold');
    pdf.text(title, 12, yPosition); // Adiciona o título na posição especificada
    pdf.setFont('Helvetica', 'normal');
    // Define a cor padrão para o texto dentro da caixa
    pdf.setTextColor(3, 3, 3); // Cor padrão para o texto dentro da caixa
    pdf.setFontSize(8); // Tamanho da fonte padrão
    return yPosition + boxHeight + 10;
  };

  const addPageContent = async () => {
    const maxPageHeight = pdf.internal.pageSize.height - 20;
    let yPosition = 30;

    await addHeader();

    // Dados do Agendamento
    yPosition = addSectionBox(pdf, 'Dados do Agendamento', yPosition, (pdf, yPosition) => {
      let boxHeight = 25;
      pdf.text(`Empresa: ${appointmentData.company_name || 'N/A'}`, 12, yPosition + 5);
      pdf.text(`Profissional: ${appointmentData.professional_name || 'N/A'}`, 12, yPosition + 10);
      pdf.text(`Paciente: ${appointmentData.patient_name || 'N/A'}`, 12, yPosition + 15);
      pdf.text(`Email: ${appointmentData.patient_email || 'N/A'}`, 12, yPosition + 20);
      pdf.text(`Telefone: ${appointmentData.patient_phone || 'N/A'}`, 12, yPosition + 25);
      pdf.text(`Celular: ${appointmentData.patient_mobile || 'N/A'}`, 12, yPosition + 30);
      pdf.text(`Data: ${formatDate(appointmentData.appointment_date)}`, 12, yPosition + 35);
      pdf.text(`Hora: ${formatTime(appointmentData.appointment_time)}`, 12, yPosition + 40);
      pdf.text(`Status: ${formatStatus(appointmentData.status) || 'N/A'}`, 12, yPosition + 45);
      return boxHeight + 30;
    });

    // Observações do Agendamento
    yPosition = addSectionBox(pdf, 'Observações', yPosition, (pdf, yPosition) => {
      let boxHeight = 15;
      pdf.text(`Notas: ${appointmentData.notes || 'Nenhuma observação'}`, 12, yPosition + 5);
      return boxHeight;
    });

    // Dados da Consulta (se existir)
    if (consultationData) {
      yPosition = addSectionBox(pdf, 'Detalhes da Consulta', yPosition, (pdf, yPosition) => {
        let boxHeight = 30;
        pdf.text(`Data da Consulta: ${formatDate(consultationData.consult_date) || 'N/A'}`, 12, yPosition + 5);
        pdf.text(`Encerramento da Consulta: ${formatTime(consultationData.end_time) || 'N/A'}`, 12, yPosition + 10);
        pdf.text(`Status da Consulta: ${formatStatus(consultationData.status) || 'N/A'}`, 12, yPosition + 15);
        pdf.text(`Observações: ${consultationData.observations || 'N/A'}`, 12, yPosition + 20);
        return boxHeight;
      });
    }

    // Verifica se precisa adicionar nova página
    if (yPosition + 20 > maxPageHeight) {
      pdf.addPage();
      currentPage++;
      yPosition = 50;
      await addHeader();
      addFooter();
    }

    addFooter();
  };

  await addPageContent();

  const pdfBlob = pdf.output('blob'); // Gera o Blob do PDF

  if (preview) {
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank'); // Abre o PDF no navegador
  }

  return pdfBlob; // Retorna o Blob para outros usos (e.g., enviar por e-mail)

};

export default showAppointmentPdf;