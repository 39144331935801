import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchConsultationById, updateConsultationById } from '../../../redux/actions/consultationActions';
import { fetchDietByPatientId, deleteDietById } from '../../../redux/actions/dietActions';
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { Formik, Field, Form, FieldArray } from 'formik';
import ModalForms from '../../../components/ConfirmationModal/ModalForms';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import * as Yup from 'yup';
import moment from 'moment';
import '../styles/UpdateConsultation.css';

const UpdateConsultation = () => {
  const location = useLocation();
  const { consultation_id: paramConsultationId } = useParams();
  // Recupera os valores enviados no estado da navegação
  const { consultation_id, patient_id, appointment_id, diet_id } = location.state || {};
  // Use o consultation_id de location.state como fallback
  const finalConsultationId = consultation_id || paramConsultationId;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [selectedDietId, setSelectedDietId] = useState(null); // ID da dieta selecionada para exclusão
  const [isDeleting, setIsDeleting] = useState(false); // Estado de exclusão

  const addBtn = "Adicionar";
  const remBtn = "Remover";

  const { currentConsultation, loading, errors } = useSelector((state) => state.consultations);
  const { currentDiet } = useSelector((state) => state.diets);
  // console.log('dietas recebidas no estado:' + JSON.stringify(currentDiet));

  const [totalMealCalories, setTotalMealCalories] = useState(0);
  const [totalDietCalories, setTotalDietCalories] = useState(0);

  const [initialValues, setInitialValues] = useState({
    consult_date: '',
    start_time: '',
    end_time: '',
    procedures: '',
    diagnosis: '',
    consult_weight: '',
    diet_id: '',
    observations: '',
    current_illnesses: [''],
    past_illnesses: [''],
    allergies: [''],
    surgeries: [''],
    medications: [''],
    family_history: [''],
    diets: [''],
  });

  // useEffect(() => {
  //   dispatch(fetchConsultationById(consultation_id));
  // }, [dispatch, consultation_id]);



  useEffect(() => {
    if (!finalConsultationId || !patient_id || !appointment_id) {
      console.error("Dados insuficientes para carregar a consulta.");
      navigate('/home/consultation/get', { replace: true });
      return;
    }

    // if (diet_id) {
    //   console.log("Diet ID recebido:", diet_id);
    // }

    dispatch(fetchConsultationById(finalConsultationId));
  }, [dispatch, finalConsultationId, patient_id, appointment_id, diet_id, navigate]);


  useEffect(() => {
    if (currentConsultation?.patient_id) {
      dispatch(fetchDietByPatientId(currentConsultation.patient_id)); // Carrega dietas relacionadas ao paciente
    }
  }, [dispatch, currentConsultation?.patient_id]);

  // useEffect(() => {
  //   console.log("Diet carregada no Redux:", currentDiet);
  // }, [currentDiet]);


  useEffect(() => {
    if (currentConsultation) {
      // console.log("Dados da consulta carregados:", currentConsultation);
      const formatedDate = moment(currentConsultation.consult_date).format('YYYY-MM-DD');

      // Formata os dados da dieta
      const formattedDiets = (currentDiet || []).map((diet) => ({
        ...diet,
        // ...console.log("Valores recebidos da Dieta - currentDiet: ", diet),
        meals: diet.meals?.map((meal) => ({
          ...meal,
          foods: meal.foods || [],
        })) || [],
      }));

      setInitialValues({
        appointment_id: currentConsultation.appointment_id || '',
        professional_id: currentConsultation.professional_id || '',
        company_id: currentConsultation.company_id || '',
        patient_id: currentConsultation.patient_id || '',
        diet_id: currentConsultation.diet_id || '',
        consult_date: formatedDate,
        start_time: currentConsultation.start_time || '',
        end_time: currentConsultation.end_time || '',
        procedures: currentConsultation.procedures || '',
        diagnosis: currentConsultation.diagnosis || '',
        consult_weight: currentConsultation.consult_weight || '',
        observations: currentConsultation.observations || '',
        current_illnesses: currentConsultation.current_illnesses || [''],
        past_illnesses: currentConsultation.past_illnesses || [''],
        allergies: currentConsultation.allergies || [''],
        surgeries: currentConsultation.surgeries || [''],
        medications: currentConsultation.medications || [''],
        family_history: currentConsultation.family_history || [''],
        diets: formattedDiets,
      });
    }
  }, [currentConsultation, currentDiet]);

  // console.log("Current Diet:", currentDiet);


  const validationSchema = Yup.object({
    consult_date: Yup.date().required('Data da consulta é necessária'),
    start_time: Yup.string().required('Hora de início é necessária'),
    // procedures: Yup.string().required('Procedimentos são necessários'),
    // diagnosis: Yup.string().required('Diagnóstico é necessário'),
    // consult_weight: Yup.number().min(1, 'Peso inválido').required('Peso é necessário'),
  });

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleEditDiet = () => {
    const dietId = Array.isArray(currentDiet) && currentDiet.length > 0 ? currentDiet[0].diet_id : null;

    navigate(`/home/diet/update/${consultation_id}`, {
      state: {
        consultation_id: currentConsultation?.consultation_id,
        patient_id: currentConsultation?.patient_id,
        appointment_id: currentConsultation?.appointment_id,
        diet_id: dietId,
      },
    });
  };

  const handleDeleteDiet = () => {
    setShowConfirmationModal(true);
    setMessage('Deseja excluir esta dieta da sua consulta associada?');
  };

  const handleConfirmDelete = async () => {
    if (!selectedDietId) {
      console.error('Erro: diet_id não foi fornecido!');
      return
    }
    try {
      await dispatch(deleteDietById(selectedDietId));
      setMessage('Dieta removida com sucesso!');
      setShowConfirmationModal(false);
      setSelectedDietId(null);
      // Recarregar dietas após exclusão
      if (currentConsultation?.patient_id) {
        dispatch(fetchDietByPatientId(currentConsultation.patient_id));
      }
    } catch (error) {
      console.error('Erro ao excluir dieta:', error);
      setMessage('Erro ao excluir a dieta. Tente novamente.');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmationModal(false);
    setSelectedDietId(null);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'Data inválida';
    const date = new Date(dateString);
    if (Number.isNaN(date)) return 'Data inválida';
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDietName = (diet_name) => {
    switch (diet_name) {
      case 'low_carb':
        return 'Dieta Low Carb';
      case 'keto':
        return 'Dieta Cetogênica';
      case 'caloric_deficit':
        return 'Dieta de Déficit Calórico';
      case 'intermittent_fasting':
        return 'Dieta do Jejum Intermitente';
      case '21_day_diet':
        return 'Dieta de 21 Dias';
      case 'detox':
        return 'Dieta Detox';
      case '7_day_detox':
        return 'Dieta Detox de 7 Dias';
      case 'hypercaloric':
        return 'Dieta Hipercalórica';
      case 'hypertrophic':
        return 'Dieta Hipertófica';
      case 'bulking':
        return 'Dieta Bulking';
      case 'protein_diet':
        return 'Dieta da Proteína';
      case 'lean_gain':
        return 'Dieta para Ganho Lean (Massa Magra)';
      case 'mediterranean':
        return 'Dieta Mediterrânea';
      case 'mediterranean_adapted':
        return 'Dieta Mediterrânea Adaptada';
      case 'antiinflammatory':
        return 'Dieta Antiinflamatória';
      case 'antioxidant':
        return 'Dieta Antioxidante';
      case 'high_fiber':
        return 'Dieta Rica em Fibras';
      case 'cardiovascular':
        return 'Dieta de Saúde Cardiovascular';
      case 'immune_boost':
        return 'Dieta para Imunidade';
      case 'vegan':
        return 'Dieta Vegana';
      case 'vegetarian':
        return 'Dieta Vegetariana';
      case 'flexitarian':
        return 'Dieta Flexitariana';
      case 'pescatarian':
        return 'Dieta Pescetariana';
      case 'paleo':
        return 'Dieta Paleo';
      case 'dash':
        return 'Dieta DASH (Hipertensão)';
      case 'fodmap':
        return 'Dieta FODMAP (Distúrbios Intestinais)';
      case 'diabetes':
        return 'Dieta para Diabetes';
      case 'low_sodium':
        return 'Dieta Hipossódica';
      case 'renal':
        return 'Dieta Renal (Insuficiência Renal)';
      default:
        return '';
    }
  };

  const formatDietGroup = (group) => {
    switch (group) {
      case 'weightLoss':
        return 'Perda de Peso';
      case 'weightGain':
        return 'Ganho de Peso';
      case 'generalHealth':
        return 'Saúde Geral';
      case 'vegetarianLifestyles':
        return 'Estilos Vegetarianos';
      case 'healthSpecific':
        return 'Saúde Específica';
      default:
        return 'Outros';
    }
  };



  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const formattedValues = {
        ...values,
        appointment_id: currentConsultation.appointment_id,
        professional_id: currentConsultation.professional_id,
        company_id: currentConsultation.company_id,
        patient_id: currentConsultation.patient_id,
        current_illnesses: values.current_illnesses.filter((item) => item.trim() !== ''),
        past_illnesses: values.past_illnesses.filter((item) => item.trim() !== ''),
        allergies: values.allergies.filter((item) => item.trim() !== ''),
        surgeries: values.surgeries.filter((item) => item.trim() !== ''),
        medications: values.medications.filter((item) => item.trim() !== ''),
        family_history: values.family_history.filter((item) => item.trim() !== ''),
        diets: values.diets.map((diet) => ({
          ...diet,
          meals: diet.meals.map((meal) => ({
            ...meal,
            foods: meal.foods.filter((food) => food.food_name.trim() !== ''),
          })),
        })),
      };

      await dispatch(updateConsultationById({ consultation_id, data: formattedValues }));
      setMessage("Consulta atualizado com sucesso!");
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        resetForm();
        navigate(`/home/consultation/show/${consultation_id}`);
      }, 2000);
    } catch (error) {
      console.error("Erro ao enviar formulário:", error);
      const errorMsg = error.message || 'Erro ao enviar formulário. Tente novamente mais tarde.';
      setMessage(errorMsg);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 2500);
    }
    setSubmitting(false);
  };
  useEffect(() => {
    if (!appointment_id) {
      console.error('Erro: appointment_id não foi fornecido!');
    }
    if (!patient_id) {
      console.error('Erro: patient_id não foi fornecido!');
    }
    if (!consultation_id) {
      console.error('Erro: consultation_id não foi fornecido!');
    }
  }, [appointment_id, patient_id, consultation_id]);


  if (loading) return <div>Carregando...</div>;
  if (errors) return <div>Erro: {errors}</div>;

  return (
    <div className="consultationMain">
      <h3>Atualizar Consulta</h3>

      {/* <p>Paciente ID: {patient_id}</p>
      <p>Agendamento ID: {appointment_id}</p>
      <p> Consulta ID: {consultation_id}</p>
      <p>Diet ID: {currentDiet.diet_id}</p> */}

      <div className="consultationForm">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, values, errors, touched }) => (
            <Form onKeyDown={handleKeyDown}>
              <div className="consultationLine">
                <div className="consultation-form-group">
                  <label>Data da Consulta:</label>
                  <Field type="date" name="consult_date" id="consultation_date" />
                </div>
                {/* <div className="consultation-form-group">
                  <label>Hora de Início:</label>
                  <Field type="time" name="start_time" id="consultation_time" />
                </div> */}
              </div>

              <div className="consultation-form-group">
                <label>Procedimentos:</label>
                <Field type="text" name="procedures" />
              </div>

              <div className="consultation-form-group">
                <label>Diagnóstico:</label>
                <Field type="text" name="diagnosis" />
              </div>

              <div className="consultation-form-group">
                <label>Observação:</label>
                <Field type="text" name="observations" />
              </div>

              {/* Campos de Dietas */}



              <div className="consultation-form-group">
                <label>Dietas</label>
                <FieldArray name="diets">
                  {({ push }) => (
                    <div>
                      {/* Lista de Dietas */}
                      {values.diets.length > 0 ? (
                        values.diets.map((diet, dietIndex) => (
                          < div key={dietIndex} className="diet-box" >
                            <div className="consultationLine">
                              <div className="box-diet-col-A">
                                <div>Nome: <span className="spanDataTitle">{formatDietName(diet.name)}</span></div>
                                <div>Criada em: <span className="spanDataTitle">{formatDate(diet.created_at)}</span></div>
                                <div>Objetivo: <span className="spanDataTitle">{diet.objective}</span></div>
                                <div>Total de Calorias: <span className="spanDataTitle">
                                  {diet.diet_total_calories}
                                  {currentConsultation.diet_total_calories}
                                  {currentDiet.diet_total_calories}

                                </span> kcal/dia</div>
                                {/* <div>Diet id: {diet.diet_id}</div> */}
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>Nenhuma dieta associada encontrada.</p>
                      )}

                      {/* Botões Condicionais */}
                      <div className="consultationButtons">
                        {values.diets.length === 0 ? (
                          // Caso não existam dietas, exibe o botão "Adicionar Dieta"
                          <button
                            className="sendBtn"
                            type="button"
                            onClick={() => navigate('/home/diet/create', { state: { patient_id, appointment_id, consultation_id } })}
                          >
                            Criar Nova Dieta
                          </button>
                        ) : (
                          // Caso existam dietas, exibe os botões "Editar" e "Excluir Dieta"
                          <>
                            <button
                              className="sendBtn"
                              type="button"
                              onClick={() => handleEditDiet()}
                            >
                              Editar Dieta
                            </button>

                            <button
                              className="backBtn"
                              type="button"
                              onClick={() => {
                                const selectedId = Array.isArray(currentDiet) && currentDiet.length > 0 ? currentDiet[0].diet_id : null;
                                // const selectedId = currentDiet?.[0]?.diet_id; // Obtém o diet_id
                                setSelectedDietId(selectedId); // Atualiza o estado com o diet_id
                                // console.log("Diet ID selecionado para exclusão:", selectedId);
                                handleDeleteDiet(selectedId); // Chama a função para confirmar exclusão
                              }}
                            >
                              Excluir Dieta
                            </button>

                          </>
                        )}
                      </div>
                    </div>
                  )}
                </FieldArray>



              </div>




              {/* Arrays de Campos (Ex.: Doenças Atuais) */}
              <div className="consultation-form-group">
                <label>Doenças Atuais:</label>
                <FieldArray name="current_illnesses">
                  {({ push, remove }) => (
                    <div>
                      {values.current_illnesses && values.current_illnesses.length > 0 ? (
                        values.current_illnesses.map((illness, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`current_illnesses[${index}]`}
                              placeholder={`Doença ${index + 1}`}
                            />
                            <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className='iconPlus' />
                            </button>
                            <button className='btnIcoAddRm' type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className='iconMinus' />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma doença atual adicionada.</p>
                          <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className='iconPlus' />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.current_illnesses && touched.current_illnesses && (
                  <div className="error-message">{errors.current_illnesses}</div>
                )}
              </div>

              {/* Outros Arrays de Campos (Alergias, Cirurgias, etc.) */}
              {/* Repita o padrão para os outros arrays, como past_illnesses, allergies, etc. */}

              {/* Doenças Prévias ou Crônicas */}
              <div className="consultation-form-group">
                <label>Doenças Prévias ou Crônicas</label>
                <FieldArray name="past_illnesses" id="past_illnesses">
                  {({ push, remove }) => (
                    <div>
                      {values.past_illnesses && values.past_illnesses.length > 0 ? (
                        values.past_illnesses.map((illness, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`past_illnesses[${index}]`}
                              placeholder={`Doença ${index + 1}`}
                            />
                            <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className='iconPlus' />
                            </button>
                            <button className='btnIcoAddRm' type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className='iconMinus' />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma Doença Prévias ou Crônicas.</p>
                          <button className='btnIcoAddRm' type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className='iconPlus' />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.past_illnesses && touched.past_illnesses && (
                  <div className="error-message">{errors.past_illnesses}</div>
                )}
              </div>

              {/* Alergias e Intolerâncias */}
              <div className="consultation-form-group">
                <label>Alergias e Intolerâncias</label>
                <FieldArray name="allergies" id="allergies">
                  {({ push, remove }) => (
                    <div>
                      {values.allergies && values.allergies.length > 0 ? (
                        values.allergies.map((allergy, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`allergies[${index}]`}
                              placeholder={`Alergia ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma alergia adicionada.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.allergies && touched.allergies && (
                  <div className="error-message">{errors.allergies}</div>
                )}
              </div>

              {/* Cirurgias e Hospitalizações */}
              <div className="consultation-form-group">
                <label>Cirurgias e Hospitalizações</label>
                <FieldArray name="surgeries" id="surgeries">
                  {({ push, remove }) => (
                    <div>
                      {values.surgeries && values.surgeries.length > 0 ? (
                        values.surgeries.map((surgery, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`surgeries[${index}]`}
                              placeholder={`Cirurgia ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhuma cirurgia ou hospitalização adicionada.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.surgeries && touched.surgeries && (
                  <div className="error-message">{errors.surgeries}</div>
                )}
              </div>

              {/* Uso de Medicamentos */}
              <div className="consultation-form-group">
                <label>Uso de Medicamentos</label>
                <FieldArray name="medications" id="medications">
                  {({ push, remove }) => (
                    <div>
                      {values.medications && values.medications.length > 0 ? (
                        values.medications.map((medication, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`medications[${index}]`}
                              placeholder={`Medicamento ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhum medicamento adicionado.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.medications && touched.medications && (
                  <div className="error-message">{errors.medications}</div>
                )}
              </div>

              {/* Histórico Familiar */}
              <div className="consultation-form-group">
                <label>Histórico Familiar</label>
                <FieldArray name="family_history" id="family_history">
                  {({ push, remove }) => (
                    <div>
                      {values.family_history && values.family_history.length > 0 ? (
                        values.family_history.map((history, index) => (
                          <div key={index} className="consultationLine">
                            <Field
                              type="text"
                              name={`family_history[${index}]`}
                              placeholder={`Histórico ${index + 1}`}
                            />
                            <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                              <FaPlusCircle className="iconPlus" />
                            </button>
                            <button className="btnIcoAddRm" type="button" onClick={() => remove(index)} data-tip={remBtn}>
                              <FaMinusCircle className="iconMinus" />
                            </button>
                          </div>
                        ))
                      ) : (
                        <>
                          <p>Nenhum histórico familiar adicionado.</p>
                          <button className="btnIcoAddRm" type="button" onClick={() => push('')} data-tip={addBtn}>
                            <FaPlusCircle className="iconPlus" />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </FieldArray>
                {errors.family_history && touched.family_history && (
                  <div className="error-message">{errors.family_history}</div>
                )}
              </div>

              <div className="consultationButtons">
                <button className="sendBtn" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Salvando...' : 'Atualizar Consulta'}
                </button>
                <button className="backBtn" type="button" onClick={() => navigate(`/home/consultation/show/${consultation_id}`)}>
                  Voltar
                </button>
              </div>
            </Form>
          )
          }
        </Formik >
        {showConfirmationModal && (
          <ConfirmationModal
            message={message}
            onConfirm={handleConfirmDelete}
            onCancel={handleCancelDelete}
            isDeleting={isDeleting}
          />
        )}
        {
          showModal && (
            <ModalForms
              message={message}
            // onConfirm={handleConfirmDelete}
            // onCancel={handleCancelDelete}
            // isDeleting={isDeleting}
            />
          )
        }
      </div >
    </div >
  );
};

export default UpdateConsultation;
